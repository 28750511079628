<template>
    <transition-group name="fade" class="c-detail" tag="section">
        <template v-if="!loading">
            <section class="c-detail-intro" key="role-intro">
                <div class="c-detail-intro__content">
                    <h1>{{ role.name }}</h1>
                    <h3>Wat is standaard inbegrepen?</h3>
                </div>
            </section>

            <section class="c-detail-info" v-if="role.descriptions" key="role-info">
                <div class="c-detail-info__list">
                    <div class="c-detail-text" v-for="desc in role.descriptions" :key="desc.id">
                        <h4>{{ desc.header }}</h4>
                        <p v-html="desc.description"></p>
                    </div>
                </div>
            </section>

            <footer class="c-detail-footer" key="role-footer">
                <router-link to="/roles" class="o-c2a-link o-c2a-link--tint o-c2a-link--reverse">
                    <span class="o-c2a-link__text">Terug naar configuratie</span>
                    <span class="o-c2a-link__icon">
                        <span class="o-c2a-link__icon-circle"></span>
                    </span>
                </router-link>
            </footer>

        </template>
    </transition-group>
</template>

<script>
    import { detailsService } from '../../services';

    export default {
        data () {
            return {
                loading: true,
                role: {},
            };
        },
        created () {
            this.getDetails();
        },
        methods: {
            getDetails () {
                // get style details
                if (this.$route.params.id)
                {
                    detailsService
                        .getById('roles', this.$route.params.id)
                        .then((result) => {
                            this.loading = false;
                            this.role = result;
                        }).catch(() => {
                            this.loading = false;
                        });
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/essentials";

    .c-detail-intro {
        display: grid;
        grid-template-columns: 10% 1fr 10%;
        padding-top: rem(50);

        @include media-breakpoint-up(sm) {
            padding-top: 7.5%;
        }

        @include media-breakpoint-up(lg) {
            grid-template-columns: 20% 1fr 20%;
        }

        &__content {
            grid-column: 2;
        }
    }

    .c-detail-info {
        display: grid;
        grid-template-columns: 10% 1fr 10%;

        @include media-breakpoint-up(lg) {
            grid-template-columns: 20% 1fr 20%;
        }

        &__list {
            border-top: rem(1) solid $gray-light;
            display: grid;
            grid-gap: rem(50) rem(15);
            grid-column: 2;
            padding-bottom: rem(50);
            padding-top: rem(50);
            margin-top: rem(30);

            @include media-breakpoint-up(sm) {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: rem(65) rem(15);
                padding-bottom: 7%;
                padding-top: 7%;
                margin-top: 5%;
            }
        }
    }

    .c-detail-footer {
        @include fixed(left 0 right 0 bottom 0);
        display: grid;
        grid-gap: rem(7);
        grid-template-columns: 1fr;
        border: rem(7) solid $white;
        z-index: 200;
    }
</style>
